<template>
  <div>
    <h1>Nieuw bestand toevoegen</h1>
    <form @submit.prevent="createFile">
      <p>
        <label>
          Naam <br/>
          <input type="text" v-model="name" required />
        </label>
      </p>

      <p>
        <label>
          Bestand <br/>
          <input type="file" @change="addFile" required />
        </label>
      </p>

      <p>
        <button>Toevoegen</button>
      </p>
    </form>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { useMutation } from 'villus';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const variables = reactive({
      name: '',
      file: null,
    });

    const router = useRouter();

    const CreateFile = `
      mutation CreateFile($name: String!,
                          $file: Upload!) {
        CreateFile(name: $name,
                   file: $file) {
          id
        }
      }
    `;

    const addFile = (evt) => {
      const [file] = evt.target.files;
      variables.file = file;
    };

    const { execute } = useMutation(CreateFile);

    const createFile = () => {
      execute(variables)
        .then(() => {
          router.replace({ name: 'Files' });
        });
    };

    return { addFile, createFile, ...toRefs(variables) };
  },
});
</script>
